<div class="container-fluid">
    <div class="row mx-3">
        <div class="col">
            <div class="card shadow py-3 px-3">
                <div class="card-body">
                    <div class="row mb-3 d-none">
                        <div class="col">
                            <button type="button" class="btn btn-light btn-sm px-5 me-5" data-bs-toggle="collapse" data-bs-target="#general" aria-expanded="false" >General</button>
                            <button type="button" class="btn btn-sm px-5" data-bs-toggle="collapse" data-bs-target="#billing" aria-expanded="false">Billing</button>
                        </div>
                    
                    </div>

                    <ul class="nav nav-pills-custom mb-5" id="pills-tab" role="tablist">
                        <li class="nav-item me-2" role="presentation">
                            <button class="nav-link active fw-bold" id="pills-general-tab" (click)="change_tab($event,'pills-general-tab')" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">General</button>
                        </li>
                        <li class="nav-item me-2" role="presentation">
                            <button class="nav-link fw-bold" id="pills-billing-tab" (click)="change_tab($event,'pills-billing-tab')" data-bs-toggle="pill" data-bs-target="#pills-billing" type="button" role="tab" aria-controls="pills-billing" aria-selected="false">Billing</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                            <div class="row">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                    <div class="row mb-3">
                                        <div class="col">
                                            <input class="d-none" type="file" id="upload-id" accept="image/*" (change)="upload($event)" />
                                        
                                            <span class="text-success fw-bold">Profile Picture</span><br/>
                                            <small>Update your profile photo</small>
                                        </div>
                                    </div>
                
                                    <div class="row mb-3" *ngFor="let data of table_list">
                                        <div class="col" >
                                            <figcaption id="file-name" class="text-center d-none"></figcaption>
                                              <div id="avatar">
                                                <img *ngIf="data.profile_photo === undefined || data.profile_photo === ''" id="profile_pic" src="../../assets/images/avatar.svg" class="main-profile-img" />
                
                                                <img *ngIf="data.profile_photo !== undefined && data.profile_photo !== ''" id="profile_pic" src="{{data.profile_photo}}" class="main-profile-img" />
                                                <label class="document-file-name" for="upload-id" style="background-color: 
                                                #225553;">
                                                    <img class="img-fluid" src="../../assets/images/ellipse.svg" />
                                                 
                                                </label>
                                              </div>
                                            
                                        </div>
                                    </div>
                
                                    <div *ngFor="let data of table_list">
                                        
                
                                        <div class="row mb-3">
                                            <div class="col">
                                                <span class="text-success fw-bold">Basic Information</span><br/>
                                            </div>
                                        </div>
                
                                        <div class="mb-3">
                                            <small class="form-label text-muted">First Name</small>
                                            <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{data.first_name}}">
                                        </div>
                
                                        <div class="mb-3">
                                            <small class="form-label text-muted">Last Name</small>
                                            <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{data.last_name}}">
                                        </div>
                
                                        <div class="mb-3">
                                            <small class="form-label text-muted">Email</small>
                                            <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{data.username}}">
                                        </div>
                
                                        <div class="mb-3">
                                            <small class="form-label text-muted">Mobile Number</small>
                                            <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{data.mobile_number}}">
                                            
                                            
                                        
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-1">
                                    <div class="vr" style="min-height: 75vh;"></div>
                                </div>
                
                                <div class="col">
                                    <div class="row mb-3">
                                        <div class="col">
                                            <span class="text-success fw-bold">Edit Profile</span><br/>
                                            
                                        </div>
                                    </div>
                
                                    <form [formGroup]="form">
                
                                    
                
                                        <div class="row mb-3">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">First Name</small>
                                                <input type="text" class="form-control {{form.get('first_name').touched && form.get('first_name').invalid? 'is-invalid' : ''}}" formControlName="first_name">
                
                                                <small class="text-danger" *ngIf="form.get('first_name').touched && form.get('first_name').invalid && form.controls['first_name'].errors?.['required']">
                                                    {{field_label['first_name']}} is required
                                                </small>
                
                                                <small class="text-danger" *ngIf="form.get('first_name').touched && form.get('first_name').invalid && form.controls['first_name'].errors?.['pattern']">
                                                    {{field_label['first_name']}} is invalid format
                                                </small>
                                            </div>
                
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Last Name</small>
                                                <input type="text" class="form-control {{form.get('last_name').touched && form.get('last_name').invalid? 'is-invalid' : ''}}" formControlName="last_name">
                
                                                <small class="text-danger" *ngIf="form.get('last_name').touched && form.get('last_name').invalid && form.controls['last_name'].errors?.['required']">
                                                    {{field_label['last_name']}} is required
                                                </small>
                
                                                <small class="text-danger" *ngIf="form.get('last_name').touched && form.get('last_name').invalid && form.controls['last_name'].errors?.['pattern']">
                                                    {{field_label['last_name']}} is invalid format
                                                </small>
                                            </div>
                                        </div>
                
                                        <div class="row">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Email</small>
                                                <input readonly type="text" class="form-control" formControlName="username">
                                            </div>
                                        </div>
                
                                        <div class="row d-none">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Email</small>
                                                <input type="text" class="form-control" formControlName="username_placeholder">
                                            </div>
                                        </div>
                
                                      
                                        
                                        <div class="row mt-4 mb-5" *ngIf="show_edit_btn">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <div class="d-grid gap-2">
                                                    <button class="btn btn-primary btn-md font-700" type="button" [disabled]="!form.dirty" (click)="save_data()"><small>Update personal info</small></button>
                                                </div>
                                            </div>
                                        </div>
                
                                    
                
                
                                        <div class="row">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto d-none">
                                                <small class="form-label text-muted"> Password</small>
                                                <input type="password" class="form-control" formControlName="password_placeholder">
                                            </div>
                                        </div>
                                    </form>
                
                                    <form [formGroup]="form3">
                                        <div class="row">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Mobile Number</small>
                                                <div class="d-grid gap-2">
                                                    <input formControlName="mobile_number" type="text" class="form-control {{form3.get('mobile_number').touched && form3.get('mobile_number').invalid? 'is-invalid' : ''}}" 
                                                    ng2TelInput
                                                    [ng2TelInputOptions]="{initialCountry: 'ph'}"
                                                    (countryChange)="onCountryChange($event)"
                                                    (intlTelInputObject)="telInputObject($event)" />
                                                </div>
                
                                                <small class="text-danger" *ngIf="form3.get('mobile_number').touched && form3.get('mobile_number').invalid && form3.controls['mobile_number'].errors?.['required']">
                                                    {{field_label['mobile_number']}} is required
                                                </small>
        
                                                <small class="text-danger" *ngIf="form3.get('mobile_number').touched && form3.get('mobile_number').invalid && form3.controls['mobile_number'].errors?.['pattern']">
                                                    {{field_label['mobile_number']}} format is invalid
                                                </small>
                                            </div>
                                        </div>
                                    </form>
                                        
                
                                        <div class="row mt-4 mb-5" *ngIf="show_edit_btn">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <div class="d-grid gap-2">
                                                    <button (click)="validate()" [disabled]="!form3.dirty || !form3.valid" class="btn btn-primary btn-md font-700" type="button"><small>Validate</small></button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="row mb-3">
                                            <div class="col">
                                                <span class="text-success fw-bold">Change Password</span><br/>
                                                <small>Update your password here</small>
                                                
                                            </div>
                                        </div>
                                    
                
                                    <form [formGroup]="form2">
                                        <div class="row d-none">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Email</small>
                                                <input type="text" class="form-control" formControlName="username">
                                            </div>
                                        </div>
                
                                        <div class="row d-none">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Username Placeholder</small>
                                                <input type="text" class="form-control" formControlName="username_placeholder">
                                            </div>
                                        </div>
                
                                        <div class="row">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Current Password</small>
                
                                                <div class="input-group">
                                                    
                                                    <input style="border-right:none !important;" type="{{show_password ? 'text' : 'password'}}" class="form-control {{form2.get('password').touched && form2.get('password').invalid? 'is-invalid' : ''}}" formControlName="password">
                                                    <img [ngStyle]="{'border-color' : form2.get('password').touched && form2.get('password').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password')" src="{{show_password? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}" />
                                                    
                                                </div>
                
                                                
                
                                                <small class="text-danger" *ngIf="form2.get('password').touched && form2.get('password').invalid && form2.controls['password'].errors?.['required']">
                                                    {{field_label['password']}} is required
                                                </small>
                
                                                <small class="text-danger" *ngIf="form2.get('password').touched && form2.get('password').invalid && form2.controls['password'].errors?.['minlength']">
                                                    {{field_label['password']}} must have 6 minimum characters
                                                </small>
                
                                                <small class="text-danger" *ngIf="form2.get('password').touched && form2.get('password').invalid && form2.controls['password'].errors?.['password_match']">
                                                    {{field_label['password']}} doesn't matched!
                                                </small>
                                            </div>
                                        </div>
                
                                        <div class="row d-none">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted"> Password</small>
                                                <input type="password" class="form-control" formControlName="password_placeholder">
                                            </div>
                                        </div>
                
                                        <div class="row">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">New Password</small>
                
                                                <div class="input-group">
                                                    
                                                    <input style="border-right:none !important;" type="{{show_password2 ? 'text' : 'password'}}" class="form-control {{form2.get('new_password_placeholder').touched && form2.get('new_password_placeholder').invalid? 'is-invalid' : ''}}" formControlName="new_password_placeholder">
                                                    <img [ngStyle]="{'border-color' : form2.get('new_password_placeholder').touched && form2.get('new_password_placeholder').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password2')" src="{{show_password2? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}"/>
                                                    
                                                </div>
                
                
                                                
                                                
                                                <small class="text-danger" *ngIf="form2.get('new_password_placeholder').touched && form2.get('new_password_placeholder').invalid && form2.controls['new_password_placeholder'].errors?.['required']">
                                                    {{field_label['new_password_placeholder']}} is required
                                                </small>
                
                                                <small class="text-danger" *ngIf="form2.get('new_password_placeholder').touched && form2.get('new_password_placeholder').invalid && form2.controls['new_password_placeholder'].errors?.['minlength']">
                                                    {{field_label['new_password_placeholder']}} must have 6 minimum characters
                                                </small>
                                            
                                            </div>
                
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <small class="form-label text-muted">Confirm Password</small>
                                                 
                                                <div class="input-group">
                                                    
                                                    <input style="border-right:none !important;" type="{{show_password3 ? 'text' : 'password'}}" class="form-control {{form2.get('conf_password_placeholder').touched && form2.get('conf_password_placeholder').invalid? 'is-invalid' : ''}}" formControlName="conf_password_placeholder">
                                                    <img [ngStyle]="{'border-color' : form2.get('conf_password_placeholder').touched && form2.get('conf_password_placeholder').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password3')" src="{{show_password3? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}" />
                                                        
                                                   
                                                </div>
                
                                                <small class="text-danger" *ngIf="form2.get('conf_password_placeholder').touched && form2.get('conf_password_placeholder').invalid && form2.controls['conf_password_placeholder'].errors?.['required']">
                                                    {{field_label['conf_password_placeholder']}} is required
                                                </small>
                
                                                <small class="text-danger" *ngIf="form2.get('conf_password_placeholder').touched && form2.get('conf_password_placeholder').invalid && form2.controls['conf_password_placeholder'].errors?.['minlength']">
                                                    {{field_label['conf_password_placeholder']}} must have 6 minimum characters
                                                </small>
        
                                                <small class="text-danger" *ngIf="form2.get('conf_password_placeholder').touched && form2.get('conf_password_placeholder').invalid && form2.controls['conf_password_placeholder'].errors?.['cp_validator']">
                                                    {{field_label['conf_password_placeholder']}} doesn't matched
                                                </small>
                                            
                                            </div>
                                        </div>
                                        <div class="row mt-4 mb-5" *ngIf="show_edit_btn">
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                                <div class="d-grid gap-2">
                                                    <button class="btn btn-primary btn-md font-700" type="button" [disabled]="!form2.dirty" (click)="change_password()"><small>Change Password</small></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                        
                                    
                                </div>
                                
                            </div>
                        </div><!--end general-->

                        <div class="tab-pane fade" id="pills-billing" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                            <div class="row" *ngIf="global.login_session['subscription_billing']['subscription'] !== undefined">
                                <div class="col-xl-8 col-lg-8 col-auto">
                                    <div class="row mb-3">
                                        <div class="col-auto">
                                            <span class="text-success fw-bold">Billing Information</span><br/>
                                        </div>
                                    </div>

                                    

                                    <div class="mb-3">
                                        <small class="form-label text-muted">Subscription</small>
                                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{commonService.ucwords(global.login_session['subscription_billing']['subscription'])}}">
                                    </div>
            
                                    <div class="mb-3">
                                        <small class="form-label text-muted">Next Due Date:</small>
                                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{global.login_session['subscription_billing']['next_due_date_parsed'] !== undefined? global.login_session['subscription_billing']['next_due_date_parsed'] : ''}}">
                                    </div>

                                    <div class="mb-3">
                                        <small class="form-label text-muted">Status</small>
                                        <input readonly type="text" class="form-control-plaintext form-control-sm" value="{{commonService.ucwords(global.login_session['subscription_billing']['status'])}}">
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="global.login_session['user_type'] == 'owner' && (this.global.login_session['subscription_billing']['status'] !== undefined && this.global.login_session['subscription_billing']['status'] == 'running')">
                                <div class="col-auto">
                                    <div class="d-grid gap-2">
                                        <button data-bs-toggle="modal" data-bs-target="#confirmation_modal" class="btn btn-danger btn-md font-700" type="button"><small>Cancel subscription</small></button>
                                    </div>
                                </div>
                            </div>
                        </div><!--end billing-->
                    </div>
        
                   
                    

                    
        
                    
                </div>
            </div>
        </div>
    </div>
    
</div>


<div class="modal fade" id="confirmation_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center align-items-center mb-3">
                    <div class="col-auto">
                        <img style="width:0.5in;height:0.5in;" class="img-fluid mx-auto d-block" src="../../assets/images/delete-icon.png" >
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p class="text-center">
                            <small class="col-form-label fw-bold" style="font-size: 9pt !important;color: rgba(49, 121, 108, 1);">Are you sure you want to cancel subscription?</small>
                        </p>
                    </div>
                </div>
                

                <div class="row justify-content-center my-3">
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                        
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="fw-bold btn btn-outline-primary btn-sm">Cancel</button>
                        </div>
                    </div>
                    
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary btn-sm fw-bold" (click)="cancel_sub()">Yes</button>
                        </div>
                    </div>
    
                </div>
            </div>

        </div>
    </div>
</div>