import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { NewComponentComponent } from './new-component/new-component.component';
import { HttpClientModule } from '@angular/common/http';
import { SearchVwComponent } from './search-vw/search-vw.component';
import { LayoutComponent } from './layout/layout.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { HeaderMenuComponent } from './layout/header-menu/header-menu.component';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideStorage,getStorage } from '@angular/fire/storage';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SettingsComponent } from './settings/settings.component';
import { SettingsVwComponent } from './settings/settings-vw/settings-vw.component';

import { MatDialogModule  } from '@angular/material/dialog';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Globals } from './global/global-variable';
import { UserManagementComponent } from './user-management/user-management.component';
import { DatePipe } from '@angular/common';
import { AccountComponent } from './account/account.component';
import { FaqComponent } from './faq/faq.component';
import { SupportComponent } from './support/support.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';

import { DatepickerModule } from 'ng2-datepicker';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { Form1Component } from './form1/form1.component';
import { LoaderComponent } from './loader/loader.component';
import { SearchManifestComponent } from './search-manifest/search-manifest.component';

//qr code generator
import { QRCodeModule } from 'angularx-qrcode';
import { GeneratedManifestComponent } from './generated-manifest/generated-manifest.component';

//csv parser
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { BulkSearchComponent } from './bulk-search/bulk-search.component';
import { BulkSearchExportComponent } from './bulk-search-export/bulk-search-export.component';
import { BulkSearchVwComponent } from './bulk-search-vw/bulk-search-vw.component';
import { BulkSearchVwSearchComponent } from './bulk-search-vw/bulk-search-vw-search/bulk-search-vw-search.component';
import { SearchPepVwComponent } from './search-pep-vw/search-pep-vw.component';

import { ManageGroupsComponent } from './manage-groups/manage-groups.component';
import { UserInviteValidateComponent } from './user-invite-validate/user-invite-validate.component';
import { SearchWikiVwComponent } from './search-wiki-vw/search-wiki-vw.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MayaVerificationComponent } from './maya-verification/maya-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    NewComponentComponent,
    SearchVwComponent,
    LayoutComponent,
    SideMenuComponent,
    HeaderMenuComponent,
    LoginComponent,
    SettingsComponent,
    SettingsVwComponent,
    DashboardComponent,
    UserManagementComponent,
    AccountComponent,
    FaqComponent,
    SupportComponent,
    AuditTrailComponent,
    AuditLogComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    Form1Component,
    LoaderComponent,
    SearchManifestComponent,
    GeneratedManifestComponent,
    CompanyInformationComponent,
    BulkSearchComponent,
    BulkSearchExportComponent,
    BulkSearchVwComponent,
    BulkSearchVwSearchComponent,
    SearchPepVwComponent,
    ManageGroupsComponent,
    UserInviteValidateComponent,
    SearchWikiVwComponent,
    CheckoutComponent,
    MayaVerificationComponent,
    
    
  ],
  imports: [
    BrowserModule,
    
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    MatDialogModule ,
    Ng2TelInputModule,
    DatepickerModule,
    QRCodeModule,
    NgxCsvParserModule, //ngx parser
    
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage())
  ],
  providers: [ Globals,DatePipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }
