
<div class="container-fluid">
  
    <form [formGroup]="filter_form">
        <div class="row align-items-center justify-content-start ms-3 me-3">
            <div class="col-auto">
                All manifests
            </div>

            <!--
            <div class="col col-auto">
                <div class="d-grid gap-2">
                    <button type="button" *ngIf="show_add_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal_view">Add New User <img src="../../assets/images/add.svg" /></button>
                </div>
                
            </div>
            <div class="col">
    
            </div>
            <div class="col-auto">
                <div class="input-group">
                    <span class="input-group-text bg-white">
                        <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                    </span>
                    <input style="border-left:none;" (keyup.enter)="search()" formControlName="filter_search" (blur)="search()" placeholder="Search name"  type="text" class="form-control">
                </div>
                
            </div>
            -->
        </div>
    </form>
   
    

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
            <p class="text-muted"></p>
            <div class="shadow bg-white rounded">
                <div class="card border-0 shadow" *ngIf="show_no_data">
                    <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
    
                        <div class="col-auto">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-auto">
                                    <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                </div>
                                <div class="col-12">
                                    <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive" style="max-height:500px;" *ngIf="!show_no_data">
                    <table class="table align-middle" style="min-width:2in;table-layout: fixed;">
                        <thead>
                            <tr>
                                <th class="text-muted text-left px-3">Title</th>
                                <th class="text-muted text-center px-3">Date Generated</th>
                                <th class="text-muted px-3" ></th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of table_list; let x = index">
                                <td class="text-left px-3">{{data.file_name.substring(0,data.file_name.indexOf('.pdf'))}}</td>
                                <td class="text-center px-3">{{data.date_created_str}}</td>
                                <td class="text-center px-3">
                                    <a target="_blank" href="{{data.view_link}}"><img style="width:10.11px;height:12.64px;" class="img-fluid" src="../../assets/images/download_icon.png" /></a>
                                </td>
                                
                                
                            </tr>
                        </tbody>
                    </table>
    
                    
                </div>

                

                <div class="row container-fluid justify-content-end align-items-center mt-4" *ngIf="!show_no_data">
                    <div class="col-auto">
                        <form [formGroup]="filter_form">

                            <div class="mb-3 row align-items-center">
                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                    Rows per page
                                </label>
                                <div class="col">
                                    <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="col-auto">
                        <div class="mb-3 row align-items-center">
                            <div class="col-auto">
                                {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-auto">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination border-opacity-10">
                                <li class="page-item " (click)="prev()" >
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                    </a>
                                </li>
                             
                                <li class="page-item" (click)="next()">
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    
                </div>
            </div>
            
        </div>
    </div>
</div>


