import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchService } from '../services/search-service';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ManifestService } from '../services/manifest-service';
@Component({
  selector: 'app-search-wiki-vw',
  templateUrl: './search-wiki-vw.component.html',
  styleUrls: ['./search-wiki-vw.component.scss']
})
export class SearchWikiVwComponent {
    public year_today;
    public date_today;
    public app_version;
    data_list = new Array();
    snapshot_param = {}; //snapshot parameter
    alias_language_key = new Array();
    selected_tab = "pills-home";

    //adverse
    total_search_result = 0;
    isIncludeAdverse = 0;
    show_total_search_result    = false;
    pageNumber                  = 0;
    row_counter                 = 1;
    pageSize                    = [1,5,10];
    pagination_counter          = 0;
    adverse_list                = {};
    adverse_list_item           = new Array();
    form = this.fb.group({
        page_size_placeholder : [10],
        sort_by : ["date:d"]
     
    });
    constructor(private searchService : SearchService,
        private _activatedRouter : ActivatedRoute,
        public commonService : CommonService,
        public global : Globals,
        private datePipe : DatePipe,
        private fb : FormBuilder,
        private manifestService : ManifestService
    ){
        this.snapshot_param = this._activatedRouter.snapshot.params;
        this.isIncludeAdverse = parseInt(this.snapshot_param['isIncludeAdverse']);
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();
        this.app_version = environment.app_version; 
    }

    async ngOnInit(){
        this.global.loading    = true;
        //-------------------------------------------load data-------------------------------------------
        const request_param = {
            "action" : "wbgetclaims",
            "entity" : this.snapshot_param["id"],
            "format" : "json",
            "utf8": 1,
            "formatversion" : 2

        }
        const wikidata_api = await lastValueFrom(this.searchService.view_wikidata(request_param));
        console.log('wikidata_api',wikidata_api);

        if(wikidata_api["success"]){
            this.data_list = wikidata_api["data"];
            this.alias_language_key = Object.keys(this.data_list[this.snapshot_param["id"]+"-profile"]['aliases']);
        }else{
            this.data_list = [];
        }//end if

        //Load if Adverse New is SELECTED
        if(parseInt(this.snapshot_param['isIncludeAdverse'])){
            this.load_adverse();
        }
        //-------------------------------------------load data-------------------------------------------
        this.global.loading    = false;
    }
    selected_event : any;
    hide_link(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_event = el;

        setTimeout(() => {
            if(this.selected_event.querySelector("img").src.indexOf('images/chevron-down.svg') >= 0){
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-up.svg';
            }else{
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-down.svg';
            }//end if
        }, 500)
        
       
    }

    go_back(){
        window.close()
    }

    generate_report(){
        this.global.loading = true;
        var file_name = this.data_list[this.snapshot_param['id']+"-profile"] !== undefined? this.data_list[this.snapshot_param['id']+"-profile"]["labels"]["en"]["value"] : 'untitled';
        const request_param = {
            "created_by": this.global.login_session["id"],
            "search_id" : this.snapshot_param["id"],
            "file_name": file_name+".pdf",
            "generate_new" : true,
            "see_more_url" : window.location.href,
            "search_type" : this.snapshot_param["search_type"],
            "url": window.location.origin+"/search-result/report-wiki-single/"+this.snapshot_param["search_type"]+"/"+this.snapshot_param["id"],
            "wikidata_id": [
                this.snapshot_param["id"]
            ]
        }
        console.log(request_param);

        this.manifestService.generate_manifest_wikidata_single(request_param).subscribe(result =>{
            console.log(result);
            if(result["success"]){
                window.open(result["data"]["manifest_link"],"_blank");
            }else{
                console.log(result);
            }
            this.global.loading = false;
        });
    }

    load_adverse(){
        this.global.loading    = true;
        var full_name = (this.data_list[this.snapshot_param['id']+"-profile"] !== undefined)? this.data_list[this.snapshot_param['id']+"-profile"]["labels"]["en"]["value"] : "";
        
        //(this.data_list[0] !== undefined? this.data_list[0].FULL_NAME : "");
        const val = this.form.value;
        
        if(this.adverse_list["searchInformation"] === undefined){

        
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", environment.google_custom_search_key);
            const raw = JSON.stringify({
                "name": full_name,
                "num": val.page_size_placeholder,
                "start": this.row_counter,
                "sort" : val.sort_by
            });
            console.log('raw',raw);
            var requestOptions : any; 
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(environment.google_custom_search, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    this.adverse_list = JSON.parse(result);

                    if(this.adverse_list["data"]["searchInformation"]["totalResults"] > 0){
                        //console.log(this.adverse_list["data"]["searchInformation"]["totalResults"])
                        this.adverse_list_item = this.adverse_list["data"]["items"];
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }else{
                        this.adverse_list_item = new Array();
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }//end if
                    this.show_total_search_result = true;
                    this.global.loading = false;

                })
                .catch((error) => console.error(error));
        }
    }//


    
        
        
    prev(){
        const val           = this.form.value;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
            this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
            this.load_adverse();
        }//end if
        
        
        
    }

    next(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_search_result / val.page_size_placeholder));
        
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            
            this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
            this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
            this.load_adverse();
        }//end if
    }

    generate_report_wiki(){
        this.global.loading = true;
        
        const full_name = (this.data_list[this.snapshot_param['id']+"-profile"] !== undefined)? this.data_list[this.snapshot_param['id']+"-profile"]["labels"]["en"]["value"] : "";

        const file_name = full_name.toLocaleLowerCase()+".pdf";
        const search_id = this.snapshot_param["id"]+"-adverse";
        const request_param = {
            "search_query": full_name,
            "file_name": file_name,
            "generate_new" : true,
            "search_id" : search_id,
            "created_by" : this.global.login_session["id"],
            "search_type" : this.snapshot_param['search_type'],
            "url": window.location.origin+"/search-result/report-adverse-single/"+this.snapshot_param['search_type']+"/"+search_id

        };
        console.log(request_param);
        this.manifestService.generate_manifest_adverse_single(request_param).subscribe(result =>{
            console.log(result);
            if(result["success"]){
                window.open(result["data"]["manifest_link"],"_blank");
            }else{
                console.log(result);
            }
            this.global.loading = false;
        });

    }//end

    selected_t(id){
        this.selected_tab = id;
    }//end if
}
