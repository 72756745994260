import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchService } from '../services/search-service';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-wiki-vw',
  templateUrl: './search-wiki-vw.component.html',
  styleUrls: ['./search-wiki-vw.component.scss']
})
export class SearchWikiVwComponent {
    public year_today;
    public date_today;
    public app_version;
    data_list = new Array();
    snapshot_param = {}; //snapshot parameter
    alias_language_key = new Array();
    
    constructor(private searchService : SearchService,
        private _activatedRouter : ActivatedRoute,
        public commonService : CommonService,
        public global : Globals,
        private datePipe : DatePipe,
        private fb : FormBuilder
    ){
        this.snapshot_param = this._activatedRouter.snapshot.params;
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();
        this.app_version = environment.app_version; 
    }

    async ngOnInit(){
        this.global.loading    = true;
        //-------------------------------------------load data-------------------------------------------
        const request_param = {
            "action" : "wbgetclaims",
            "entity" : this.snapshot_param["id"],
            "format" : "json",
            "utf8": 1,
            "formatversion" : 2

        }
        const wikidata_api = await lastValueFrom(this.searchService.view_wikidata(request_param));
        console.log('wikidata_api',wikidata_api);

        if(wikidata_api["success"]){
            this.data_list = wikidata_api["data"];
            this.alias_language_key = Object.keys(this.data_list[this.snapshot_param["id"]+"-profile"]['aliases']);
        }else{
            this.data_list = [];
        }
        //-------------------------------------------load data-------------------------------------------
        this.global.loading    = false;
    }
    selected_event : any;
    hide_link(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_event = el;

        setTimeout(() => {
            if(this.selected_event.querySelector("img").src.indexOf('images/chevron-down.svg') >= 0){
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-up.svg';
            }else{
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-down.svg';
            }//end if
        }, 500)
        
       
    }

    go_back(){
        window.close()
    }
}
