import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
import { Globals } from "../global/global-variable";
import axios from "axios";
@Injectable({
    providedIn : "root"
})

export class SearchService {
    constructor(private router: Router,
        private http: HttpClient,
        private global : Globals,
        private lib : CommonService){        
    }

    public filterList(q): Observable<result_data>{
        
        
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });

        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.get_data_staging,q,requestOptions);
    }

    public elasticsearchList(index,q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.elastic_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.elastic_url+'/'+index+'/_search',q,requestOptions);
    }

    public open_api_search(q,request_query) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.open_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.open_api_pep+"?"+request_query,q,requestOptions);
    }

    public view_open_api_search(q) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : environment.open_api_key
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.get(environment.view_api_pep+"/"+q,requestOptions);
    }
    

    public search_wikidata(q) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.search_wikidata,q,requestOptions);
    }

    public view_wikidata(q) : Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.view_wikidata,q,requestOptions);
    }

    

    public sync_data(): Observable<result_data>{
        const requestOptions: Object = {
            responseType: 'json'
        }//end
        return this.http.get<result_data>(environment.process_data_staging,requestOptions);
    }
}

export interface result_data {}