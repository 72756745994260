

<div class="container-fluid">




    

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
           
                <!--<p class="fw-bold mt-4 mb-4" *ngIf="show_total_search_result">{{total_search_result}} result(s) -->
                <p class="fw-bold mt-4 mb-4" *ngIf="show_total_search_result">{{lib.toCurrency_precision(total_search_result,0)}} result(s)
                    <!-- <a target="_blank" class="ms-3 btn btn-sm btn-primary text-white" data-bs-toggle="modal" data-bs-target="#confirmation_modal">Generate report</a> -->
                    <a target="_blank" href="{{view_link}}" class="ms-3 btn btn-sm btn-primary font-700">Generate report</a>
                </p>
                
                <form class="mt-4 mb-4 d-none" [formGroup]="form">
                    <p >Filter</p>
                    
                    <div class="form-check form-check-inline">
                        <input class="form-check-input filter_grp1" formControlName="filter_all" type="checkbox" (change)="check($event,'filter_all')">
                        <label class="form-check-label" for="flexCheckDefault">
                            All
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="filter_first_name"  type="checkbox" (change)="check($event,'filter_first_name')">
                        <label class="form-check-label" for="flexCheckDefault">
                            First Name
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="filter_last_name"  type="checkbox" (change)="check($event,'filter_last_name')">
                        <label class="form-check-label" for="flexCheckDefault">
                            Last Name
                        </label>
                    </div>
                    
                    
                </form>
                
                
                <div class="shadow bg-white rounded" *ngIf="show_total_search_result && enable_elastic">
                    <div class="table-responsive bg-white rounded" style="max-height:500px;">
                        <table class="table align-middle" *ngIf="show_total_search_result" style="min-width:2in;">
                            <thead>
                                <tr>
                                    
                                    <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                    <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Source</th>
                                    <th class="text-muted px-3">Full Name</th>
                                    <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                    <th class="text-muted px-3" style="min-width:1.3in;max-width:1.3in;">Listed On</th>
                                    <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of data_list; let x = index">
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="px-3">{{data['_source'].SOURCE_LIST}}</td>
                                    <td class="px-3 fw-bold">{{ form.value.search_type == 'individual'? data['_source'].FULL_NAME : data['_source'].FIRST_NAME}}</td>
                                    <td class="px-3" style="min-width:2in;max-width:2in;">
                                        
                                        <a id="c{{data['_source'].ID}}" (click)="hide_link($event,'c'+data['_source'].ID)" *ngIf="data['_source'].INDIVIDUAL_ALIAS_PARSED_COUNT > 0" data-bs-toggle="collapse" href=".c{{data['_source'].ID}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                        <span class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_G;let x = index">
                                            <div *ngIf="x==0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                <label class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div class="d-inline-block">
                                                   
                                                    
    
                                                    <span>
                                                        {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                        
                                       
    
                                        <span class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_G;let x = index">
                                            
                                            <div *ngIf="x > 0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
    
                                        <span  class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_L;let y = index">
                                            
                                            <div *ngIf="alias.QUALITY !== undefined && (alias.QUALITY=='Low')" class="row align-items-center">
                                                <label *ngIf="y==0" class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
                                    </td>
                                    
                                    <td class="px-3">{{data['_source'].LISTED_ON_PARSED}}</td>
                                    <td class="text-center">
                                        <div class="d-grid gap-2">
                                            <a target="_blank" [routerLink]="['/search/'+form.value.search_type, data['_source'].ID,'sanction']" type="button" class="btn btn-primary btn-sm font-700 py-2">View</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            
                            
                        </table>
                    </div>

                    <div class="row container-fluid justify-content-end align-items-center mt-4">
                        <div class="col-auto">
                            <form [formGroup]="form" *ngIf="show_total_search_result">

                                <div class="mb-3 row align-items-center">
                                    <label for="inputPassword" class="col-auto col-form-label font-14">
                                        Rows per page
                                    </label>
                                    <div class="col">
                                        <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                            <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                        </select>
                                    </div>
                                </div>

                                

                                
                            </form>
                        </div>

                        <div class="col-auto">
                            <div class="mb-3 row align-items-center">
                                <div class="col-auto">
                                    {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                </div>
                            </div>
                            
                        </div>

                        <div class="col-auto">
                            <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                <ul class="pagination border-opacity-10">
                                    <li class="page-item " (click)="prev()" >
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                        </a>
                                    </li>
                                 
                                    <li class="page-item" (click)="next()">
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <!--firebase search-->
                <div class="shadow bg-white rounded" *ngIf="show_total_search_result && !enable_elastic">
                    <div class="table-responsive bg-white rounded" style="max-height:500px;">
                        <table class="table align-middle" *ngIf="show_total_search_result" style="min-width:2in;">
                            <thead>
                                <tr>
                                    
                                    <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                    <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Source</th>
                                    <th class="text-muted px-3">Full Name</th>
                                    <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                    <th class="text-muted px-3" style="min-width:1.3in;max-width:1.3in;">Listed On</th>
                                    <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of data_list; let x = index">
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="px-3">{{data.SOURCE_LIST}}</td>
                                    <td class="px-3 fw-bold">{{ form.value.search_type == 'individual'? data.FULL_NAME : data.FIRST_NAME}}</td>
                                    <td class="px-3" style="min-width:2in;max-width:2in;">
                                        
                                        <a id="c{{data.ID}}" (click)="hide_link($event,'c'+data.ID)" *ngIf="data.INDIVIDUAL_ALIAS_PARSED_COUNT > 0" data-bs-toggle="collapse" href=".c{{data.ID}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                        <span class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_G;let x = index">
                                            <div *ngIf="x==0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                <label class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div class="d-inline-block">
                                                   
                                                    
    
                                                    <span>
                                                        {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                        
                                       
    
                                        <span class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_G;let x = index">
                                            
                                            <div *ngIf="x > 0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
    
                                        <span  class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_L;let y = index">
                                            
                                            <div *ngIf="alias.QUALITY !== undefined && (alias.QUALITY=='Low')" class="row align-items-center">
                                                <label *ngIf="y==0" class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
                                    </td>
                                    
                                    <td class="px-3">{{data.LISTED_ON_PARSED}}</td>
                                    <td class="text-center">
                                        <div class="d-grid gap-2">
                                            <a target="_blank" [routerLink]="['/search/'+form.value.search_type, data.ID]" type="button" class="btn btn-primary btn-sm">View</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            
                            
                        </table>
                    </div>

                    <div class="row container-fluid justify-content-end align-items-center mt-4">
                        <div class="col-auto">
                            <form [formGroup]="form" *ngIf="show_total_search_result">

                                <div class="mb-3 row align-items-center">
                                    <label for="inputPassword" class="col-auto col-form-label font-14">
                                        Rows per page
                                    </label>
                                    <div class="col">
                                        <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                            <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                        </select>
                                    </div>
                                </div>

                                

                                
                            </form>
                        </div>

                        <div class="col-auto">
                            <div class="mb-3 row align-items-center">
                                <div class="col-auto">
                                    {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="col-auto">
                            <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                <ul class="pagination border-opacity-10">
                                    <li class="page-item " (click)="prev()" >
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                        </a>
                                    </li>
                                 
                                    <li class="page-item" (click)="next()">
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        
                        
                    </div>
                </div>
                <!--firebase search-->
                
                <div class="card border-0 shadow" *ngIf="show_total_search_result == 0">
                    <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
    
                        <div class="col-auto">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-auto">
                                    <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                </div>
                                <div class="col-12">
                                    <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
        </div>
    </div>
</div>

<!--
<div class="container">
    <div class="row justify-content-center">
        <div class="col-xxl-1 col-xl-1 col-lg-1">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-success" (click)="sync_data()">Sync</button>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Search</span>
                <input placeholder="Enter Name (Word Match)"  type="text" class="form-control" #input >
            </div>
        </div>
        <div class="col-xxl-1 col-xl-1 col-lg-1">
            

            <div class="d-grid gap-2">
                <button type="button" class="btn btn-primary" (click)="refresh_list(input.value)">Search</button>
            </div>
        </div>
        
    </div>

    <div class="row justify-content-center">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
            
            
        </div>
    </div>
</div>
-->


<div class="modal fade" id="confirmation_modal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                
                <button #confirmation_modal_close_button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        
            </div>
            <div class="modal-body">
              
                
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p style="font-size: 24px !important;font-style: normal !important;font-weight: 700 !important;line-height: 28px !important;">
                            Generating reports is a billed service.
                        </p>

                        
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                
                        <p class="text-center" style="font-family: DM Sans !important; font-size: 18px !important; font-style: normal !important; font-weight: 400 !important; line-height: normal !important;">
                            You will be billed for all reports generated by the end of the month.<br/>
                            Click "Continue" to generate a report.
                        </p>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center p-3">
                    <div class="col-xl-3 col-lg-3 col-auto">

                        <div class="d-grid gap-2">
                            <a (click)="closemodal()" target="_blank" href="{{view_link}}" class="btn btn-primary">Continue</a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

