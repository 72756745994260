<div class="navbar_float_left v2-color-side-middle">
    <div style="min-height: 0.76in;border-bottom: 1px solid white;">
        <div class="pt-3 ps-5">
            <img class="img-fluid" src="../../assets/images/uchech logo.svg">
        </div>
       
    </div>
    
    <div class="pt-3" >
        <app-side-menu></app-side-menu>
    </div>

    <div class="valign bottom">
        <div class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </div>
        
    </div>
    
</div>


<div class="navbar_float_right bg-dark-green" style="min-height: 0.76in;">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col">
                <nav class="navbar navbar-expand-md pt-3">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div class="v2-color-side-middle offcanvas offcanvas-start collapse" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header" style="border-bottom: 1px solid white;">
                            <div style="min-height: 0.76in;">
                                <div class="pt-3 ps-5">
                                    <img class="img-fluid" src="../../assets/images/uchech logo.svg">
                                </div>
                               
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <app-side-menu></app-side-menu>

                            <div class="valign bottom">
                                <div class="text-center">
                                    <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                                        <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                                    </small>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-auto">
                <div class="row align-items-center justify-content-end" style="min-height: 0.76in;">
                    
                    <div class="col-auto me-5">
                        <a  href="javascript: void(0)" class="navbar-brand text-white" (click)="go_back()">
                            <span class="me-2">x</span> <small>Close page</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="view-bg">
    <div class="view_bg_body">
        <!--individual-->
        <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'individual'">
            <div class="col-xxl-10 col-xl-10 col-lg-10">
                <div class="card shadow  mb-5">
                    <div class="card-header bg-transparent py-4 px-4">
                        
    
                        <div class="mb-3 row align-items-center">
                            <div class="col-2">
                                <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] !== undefined">
                                        <img style="height:131px;width:131px;" class="img-fluid" src="{{data_list['image_url']+data_list[snapshot_param['id']+'-profile']['claims']['P18'][0]['mainsnak']['datavalue']['value']}}"/>
                                    </span>
                                    <img *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] === undefined" style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                                </span>
                                
                            </div>
                            <h4 class="col text-bg-dark-green">{{data_list[snapshot_param['id']+"-profile"] !== undefined? data_list[snapshot_param['id']+"-profile"]["labels"]["en"]["value"] : ""}}</h4>
                            
                        </div>
                    </div>
                    <div class="card-body px-5">
                        <div class="collapse-success px-3 py-3 mb-3">
                            <div class="row justify-content-end">
                                <div class="col text-success fw-bolder">
                                    Consolidated PEP List
                                </div>
                                <div class="col-auto">
                                    <a id="wiki" (click)="hide_link($event,'wiki')" data-bs-toggle="collapse" href=".wiki" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid wiki collapse">
                            <h5 class="text-success mt-3 mb-4">Personal Details</h5>
                            
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Family name</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P734']; let x = index">
                                        <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>First name</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P735']; let x = index">
                                        <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                    </p>
                                </div>
                            </div>


                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Images</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                        <div *ngIf="data_list[snapshot_param['id']+'-profile']['claims']['P18'] !== undefined">
                                            <div class="row">
                                                <div class="col-auto" *ngFor="let data of data_list[snapshot_param['id']+'-profile']['claims']['P18']; let x = index">
                                                    <img style="width: 131px;height:131px;" src="{{data_list['image_url']+data['mainsnak']['datavalue']['value']}}"/>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    
                                </div>
                            </div>
                            

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Country of citizenship</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P27']; let x = index">
                                        <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Birth Date</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P569']; let x = index">
                                        <label class="col-form-label">{{data["text"]}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Place of Birth</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['P19'] !== undefined">
                                        <p *ngFor="let data of data_list['P19']; let x = index">
                                            <label class="col-form-label">{{data["labels"]["en"]["value"]}}</label>
                                        </p>
                                    </span>
                                </div>
                            </div>

                            <h5 class="text-success mt-5 mb-4">Also known as</h5>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Aliases</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                        <!--<p *ngFor="let data of data_list[snapshot_param['id']+'-profile']['aliases'] | keyvalue"></p>-->
                                        <p *ngFor="let data of alias_language_key; let x = index">

                                            <label class="col-form-label"><b>{{commonService.map_language(data.trim())}}</b></label><br/>
                                            <span *ngFor="let lang of data_list[this.snapshot_param['id']+'-profile']['aliases'][data]; let x = index">
                                                <label class="col-form-label">{{lang["value"]}}</label><br/>
                                            </span>
                                           
                                        </p>
                                    </span>
                                    
                                </div>
                            </div>

                            <h5 class="text-success mt-5 mb-4">Other details</h5>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Position Held</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    

                                    <span *ngIf="data_list['P39'] !== undefined">
                                        <div *ngFor="let data of data_list['P39']; let x = index">
                                            
                                            <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                            <div class="row align-items-center" *ngIf="data['start_date'].length > 0">
                                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                                    
                                                    <label class="col-form-label">
                                                        <b>Start Date</b>
                                                    </label>
                                                </div>
                                                <div class="col">
                                                    <span *ngFor="let d of data['start_date']; let x = index">
                                                        <label class="col-form-label">{{d}}</label>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="row align-items-center"*ngIf="data['end_date'].length > 0">
                                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                                    <label class="col-form-label">
                                                        <b>End Date</b>
                                                    </label>
                                                </div>
                                                <div class="col">
                                                    <span *ngFor="let d of data['end_date']; let x = index">
                                                        <label class="col-form-label">{{d}}</label>
                                                    </span>
                                                </div>
                                            </div>
                                            <hr *ngIf="data_list['P39'].length > 1"/>
                                        </div>

                                    </span>
                                    
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Comments</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                        
                                        <label class="col-form-label">{{data_list[snapshot_param['id']+'-profile']['descriptions']['en']['value']}}</label>
                                        
                                    </span>
                                    
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Ref. No.</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list[snapshot_param['id']+'-profile'] !== undefined">
                                        
                                        <label class="col-form-label">{{data_list[snapshot_param['id']+'-profile']['id']}}</label>
                                        
                                    </span>
                                    
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Spouse</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P26']; let x = index">
                                        <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Father</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P22']; let x = index">
                                        <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Mother</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P25']; let x = index">
                                        <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                    </p>
                                </div>
                            </div>


                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Sibling</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P3373']; let x = index">
                                        <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                    </p>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Child</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <p *ngFor="let data of data_list['P40']; let x = index">
                                        <label class="col-form-label">{{data['labels']['en']['value']}}</label>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>